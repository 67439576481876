/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.title {
    font-weight: bold;
    color: darkred;
}

.caseUpper {
    text-transform: uppercase;
}

input {
    text-transform:uppercase;
}

textarea {
    text-transform: uppercase;
}

.navbar-header {
    background-color: #fdf5e6;
}

.form-box {
    min-width: 100%;
    height: 200vh;
    border: 2px darkblue solid; 
    border-bottom: 2px white solid;
    margin: 0vw;
    padding: 1vw;
    position: relative;
    /* position:relative for .prevBtn and .nextBtn position:absolute; */
}

.display-box {
    min-width: 100%;
    height: 80vh;
    border: 2px darkblue solid;
    border-bottom: 2px white solid;
    margin: 0vw;
    padding: 1vw;
    position: relative;
    /* position:relative for .prevBtn and .nextBtn position:absolute; */
}

.styled-table {
    border-collapse: collapse;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
    .styled-table tr th {
        background-color: lightyellow;
        border: 1px solid gray;
        color: black;
        text-align: left;
    }
    .styled-table th,
    .styled-table td {
        border: 1px solid gray;
        padding: 5px 5px;
    }

